.exhut-table tr {
  line-height: 30px;
}

.exhut-table tr td:first-child {
  background-color: var(--exhut-primary-extra-light);
  width: 50%;
}

.exhut-table tr td {
  padding: 20px;
  border-top-width: 1px;
  border-top-color: var(--exhut-primary-extra-light);
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--exhut-primary-extra-light);
  border-bottom-style: solid;
  border-right-width: 1px;
  border-right-color: var(--exhut-primary-extra-light);
  border-right-style: solid;
  border-left-width: 1px;
  border-left-color: var(--exhut-primary-extra-light);
  border-left-style: solid;
  border-spacing: 15px;
}
