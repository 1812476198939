html {
  scroll-behavior: smooth;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --exhut-primary: #7f1bd0;
  --exhut-primary-light: #be83ee;
  --exhut-primary-extra-light: #ddb3ff;
  --exhut-black: #020202;
  --exhut-dark-grey: #7a7a7a;
  --exhut-green: #02a802;
  --exhut-light-grey0: #969696;
  --exhut-light-grey3: #e2e2e2;
  --exhut-light-grey5: #ececec;
  --exhut-orange: #e4a701;
  --exhut-red: #ff0000;
  --exhut-light-red: #ff7171;
  --exhut-dark-red: crimson;
  --exhut-total-green-light: #28ffd7;
  --exhut-total-green: #06b191;
  --exhut-white: #ffffff;
  --exhut-blue: #2196f3;
  --exhut-dark-blue: blue;
  --exhut-ramar-blue: #66baff;
  --exhut-yellow: #eeff00;
  --exhut-ivory: #e7e9e5;
  --exhut-light-green: #e8ffd1;
  --exhut-light-red: #ffcccc;
}

.body {
  min-height: 600px;
}

.bg-white {
  background-color: white;
}

.bg-purple {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(122, 74, 255, 1) 0%,
    rgba(168, 86, 255, 1) 100%
  );
}

.bg-ivory {
  background: linear-gradient(
    0deg,
    rgba(208, 208, 208, 1) 13%,
    rgba(231, 231, 231, 1) 44%
  );
}

.bg-grey {
  background-color: lightgrey;
}

.background-grey5 {
  background-color: var(--exhut-light-grey5);
}

.splitleft {
  width: 20%;
  background: var(--exhut-primary);
  top: 0;
  overflow-y: auto;
  height: 100vh;
}

.splitright {
  height: 100vh;
  overflow-y: auto;
  overflow-x: scroll;
  width: 100%;
}

/* used in mobile view to disable scrollbar, otherwise it looks ughly to have two scrollbars */
.mobile-splitright {
  overflow-y: hidden;
  height: 100%;
  margin-bottom: 80px;
}

.title {
  text-align: center;
  font-size: xx-large;
  margin: 3% 0% 2% 0%;
  font-weight: bold;
}

/* Remove the above .title after we start using this. This is usually used for title with extra
top margin for status message. */
.title-extra {
  text-align: center;
  font-size: 3vw;
  margin: 5% 0% 2% 0%;
  font-weight: bold;
}

#home-body #org-tile-container {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(124, 0, 189, 1) 36%,
    rgba(2, 128, 120, 1) 100%
  );
  height: 100vh;
}
.subtitle {
  text-align: center;
  font-size: larger;
  margin: 3% 0% 3% 0%;
  font-weight: bold;
}

/* The total font size under tilesmall should be 1.5 */
.tile-small .total {
  font-size: 1.5vw;
}

select {
  height: 45px;
  padding: 5px 10px;
  border: 1px solid var(--exhut-dark-grey);
  border-radius: 8px;
  font-size: large;
}

input.borderless {
  border: 0px;
}

.largerText {
  font-size: 20px;
}

.largeText {
  font-size: 18px;
}

.mediumText {
  font-size: 12px;
}

.smallText {
  font-size: 10px;
}

.boldText {
  font-weight: bold;
}

.plainText {
  font-weight: normal;
}

.errorMsg {
  color: var(--exhut-red);
}

.error.fancy {
  color: var(--exhut-light-red);
}

.successMsg {
  color: var(--exhut-green);
}

.warnMsg {
  color: orange;
}

.infoMsg {
  color: var(--exhut-dark-grey);
}

.helpMsg {
  font-size: small;
  color: var(--exhut-dark-grey);
}

.medium-font {
  font-size: medium;
}

.medium-small-font {
  font-size: small;
}

.small-font {
  font-size: x-small;
}

.blueMsg {
  color: #3d8ee6;
}

.purple {
  color: var(--exhut-primary-light);
}

.navyblueMsg {
  color: navy;
}

/* Use this within the div of status msg */
.statusMsg {
  justify-content: center;
  margin: 10px 0px;
  display: flex;
  min-height: 1em;
}

.greeting {
  -webkit-animation: fadein 3.5s;
  animation: fadein 3.5s;
}

.greeting-body {
  margin-left: 2em;
  font-size: 1.3em;
  line-height: 2em;
}

.tableHeader {
  font-weight: bold;
}

/* Use this for multiple links which will be space-between */
.divHeaderSpread {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
}

.divHeader {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: center;
}

.hyperlink {
  color: darkblue;
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.hyperlink-no-color {
  margin-right: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.hyperlink-green {
  color: var(--exhut-total-green);
  margin: 0px 10px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.hyperlink-yellow {
  color: var(--exhut-yellow);
  margin: 0px 10px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.ant-btn-primary {
  background: linear-gradient(135deg, #6253e1, #04befe);
  color: var(--exhut-white);
  margin: 0px 5px;
}

#upgrade-enterprise-btn {
  background: radial-gradient(
    circle,
    rgba(0, 24, 105, 1) 0%,
    rgba(0, 93, 203, 1) 100%
  );
  color: var(--exhut-white);
  margin: 0px 5px;
}

#upgrade-starter-btn {
  background: radial-gradient(
    circle,
    rgba(1, 124, 255, 1) 0%,
    rgba(65, 185, 255, 1) 100%
  );
  color: var(--exhut-white);
  margin: 0px 5px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 0px 20px;
  min-width: 120px;
  padding: 40px;
  min-height: 120px;
}

.form-body {
  margin-left: 20%;
  margin-bottom: 44px;
  margin-right: 10%;
}

.margin-center {
  margin: 0px 44px;
}

sup {
  color: red;
  font-weight: bold;
}

.iconSize {
  width: 20px;
  height: 20px;
}

.iconSizeLarge {
  width: 30px;
  height: 30px;
}

.iconSizeSmall {
  width: 15px;
  height: 15px;
}

.divCenter {
  display: flex;
  text-align: center;
  justify-content: center;
}

.divLeft {
  display: flex;
  text-align: center;
  justify-content: flex-start;
}

.divRight {
  display: flex;
  text-align: center;
  justify-content: flex-end;
}

.right-align {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.divFlex {
  display: flex;
}

.divCenterAlign {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divTextAlignOnly {
  display: flex;
  text-align: center;
  align-items: center;
}

.divAlignItemsOnly {
  display: flex;
  align-items: center;
}

.align-items {
  display: flex;
  align-items: center;
}

.divSpread {
  display: flex;
  justify-content: space-between;
}

.divSpreadCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divSpreadEven {
  display: flex;
  justify-content: space-evenly;
}

.divSpreadRight {
  display: flex;
  justify-content: flex-end;
}

.divColumn {
  display: flex;
  flex-direction: column;
}

.divColumnSpread {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divColumnCenterAlign {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divColumnCenterAlign > * {
  margin: 20px 0px 0px 0px;
}

.divRightAlign {
  align-items: flex-end;
}

.divRightTextAlign {
  text-align: end;
}

/* Animations to fade the snackbar in and out */
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeinstatus {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.status-msg-show {
  position: fixed;
  top: 0px;
  left: 50%;
  padding: 10px;
  width: 400px;
  animation-name: fadeinstatus;
  animation-duration: 1s;
  opacity: 0;
  height: 1em;
  color: var(--fnx-white);
  text-align: center;
  font-size: smaller;
  border-radius: 0px 0px 10px 10px;
}

.status-msg-hide {
  visibility: hidden;
  position: fixed;
  top: 0px;
}

.clickable {
  border: 0px solid var(--fnx-black);
  border-radius: 10px;
  padding: 10px;
  background-color: var(--fnx-light-grey5);
  margin: 0px 10px;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.topMargin10P {
  margin: 10% 0% 5% 0%;
}

.topMargin5P {
  margin: 5% 0%;
}

.topMargin2P {
  margin: 2% 0%;
}

.topMargin1P {
  margin: 1% 0% 2% 0%;
}

.topMargin0P {
  margin: 0% 0% 2% 0%;
}

select {
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--exhut-dark-grey);
}

.exhut-select {
  border: 0px;
  border-bottom: 1.5px solid cornflowerblue;
  border-radius: 0px;
  font-size: larger;
  min-width: 200px;
}

ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0em;
  margin-inline-end: 0em;
  padding-inline-start: 0em;
  list-style-type: none;
}

.footer {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(122, 74, 255, 1) 0%,
    rgba(168, 86, 255, 1) 100%
  );
  padding: 10px;
  color: white;
  font-size: 1vw;
  margin: 5px;
  text-align: center;
  font-weight: bold;
}

.footer * {
  font-size: medium;
}

.p-0 {
  padding: 0px;
}
.p-5 {
  padding: px;
}

.p-10 {
  padding: 10px;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px;
}

.p-20 {
  padding: 20px;
}

.p-40 {
  padding: 40px;
}

.p-60 {
  padding: 60px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-30 {
  padding: 0px 30px;
}

.px-40 {
  padding: 0px 40px;
}

.py-8 {
  padding: 8px 0px;
}

.py-12 {
  padding: 12px 0px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.w-80 {
  width: 80%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.vh-100 {
  height: 100vh;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-160 {
  margin-top: 160px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mx-5 {
  margin: 0px 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-60 {
  margin-right: 60px;
  margin-left: 60px;
}
.mx-80 {
  margin-right: 80px;
  margin-left: 80px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-160 {
  margin-bottom: 160px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-100 {
  margin-right: 100px;
}
.mr-150 {
  margin-right: 150px;
}

[role="dialog"] {
  min-width: 40vw;
  min-height: 40%;
}

.font-larger {
  font-size: larger;
}

.flex0-5 {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.line-30 {
  line-height: 30px;
}

.line-60 {
  line-height: 60px;
}

.animate-right {
  position: relative;
  animation: rightanimate 1s;
}

@keyframes rightanimate {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.animate-zoom {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.banner {
  font-weight: bold;
  font-size: x-large;
  background-color: black;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  color: var(--exhut-total-green-light);
  font-family: monospace;
}

hr {
  color: var(--exhut-light-grey3);
  width: 100%;
}

.MuiAlert-message {
  display: flex;
  align-items: center;
}

a {
  color: var(--exhut-blue);
  font-weight: bold;
}

.section {
  border: 1px solid darkgrey;
  border-radius: 10px;
  background-color: var(--exhut-light-grey3);

  padding: 10px;
}

#payroll-stub-container {
  font-size: x-small;
}

#payroll-stub-container tbody {
  line-height: 5px;
  font-size: x-small;
}

#payroll-stub-container thead {
  line-height: 5px;
  font-size: x-small;
}
