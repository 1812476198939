#landing-page hr {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

#landing-page p {
  color: darkslategray;
  line-height: 1.5em;
}

#landing-page sup {
  color: darkslategray;
}

.blueButton {
  align-items: center;
  border-radius: 70px;
  display: flex;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  padding: 12px 24px;
  text-decoration: none;
  width: 80px;
  background-color: var(--exhut-blue);
  color: white;
}

.download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #007bff, #00d4ff);
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  width: 200px;
}
